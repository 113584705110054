import React, { Component, Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import Logs from './Logs';
import Moment from 'react-moment';

class LogsList extends Component {
    state = {
        logs: [
            {
                hour: '15:07',
                action: 'Harry a corrigé Tom',
                type: 'Corrigé',
                feedback: 'Tom est trop fort il a tout compris'
            },
            {
                hour: '14:59',
                action: 'Tom a corrigé Lily',
                type: 'Correction',
                feedback: `l'activité a été bien comprise`
                
            },
            {
                hour: '14:56',
                action: 'Ron a corrigé Tom',
                type: 'Corrigé',
                feedback: 'Tom est trop fort il a tout compris'
            },
            {
                hour: '14:35',
                action: 'Peter a corrigé Tom',
                type: 'Corrigé',
                feedback: 'Tom est trop fort il a tout compris'
            },
            {
                hour: '14:23',
                action: 'Tom a corrigé James',
                type: 'Correction',
                feedback: `l'activité a été bien comprise`
            },
            {
                hour: '13:37',
                action: 'Luna a corrigé Tom',
                type: 'Corrigé',
                feedback: 'Tom est trop fort il a tout compris'
            }
        ]
    }
    render() {
        const today = new Date()
        const eachLog = Object.keys(this.state.logs)
            .map(key => <Logs key={key} id={key} log={this.state.logs[key]} />)
        return (
            <Fragment>
                <Button className='mb-3'>Séance du <Moment format="ll">{today}</Moment></Button>
                <Table hover>
                    <thead>
                        <tr>
                        <th>Heure</th>
                        <th>Action</th>
                        <th>Feedback</th>
                        </tr>
                    </thead>
                    { eachLog }
                </Table>
            </Fragment>
        );
    }
}

export default LogsList;