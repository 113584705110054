import React, { Component } from 'react';
import { Row, Card, CardHeader, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRandomInt } from '../../helpers/functions'

class StudentCard extends Component {
    render() {
        let user = this.props.user || {FirstName: 'Prenom', LastName: 'Nom'}

        const avatar = (<img
        alt="..."
        className="rounded-circle"
        src={require("../../assets/img/profile/test.jpg")}
        />)

        const name = (<h2>
            {user.FirstName} {user.LastName}
        </h2>)

        let randomStatus = user.status

        switch (randomStatus) {
            case 0:
                randomStatus = 'border-danger'
                break ;
            case 1:
                randomStatus = 'border-primary'
                break ;
            default:
                randomStatus = 'border-success'
                break ;
        }

        return (
            <Card className={`shadow mb-4 student-card ${this.props.color || randomStatus}`}>
                <CardHeader className="profile-header">
                    <Row className="align-items-center">
                        <Col>
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                            Profil de
                        </h6>
                        {this.props.link ? <Link 
                            to={{
                                pathname: `class/${user.ID}`,
                                user:user
                            }}
                            >
                            {name}
                        </Link> : name}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className='pt-0'>
                    <div className='user-avatar'>
                    {this.props.link ?
                        <Link 
                            to={{
                                pathname: `class/${user.ID}`,
                                user:user
                            }}>
                        {avatar}
                        </Link>
                        : 
                        avatar
                        }
                        <h3>{user.points || getRandomInt(1000, 5000)} Forces</h3>
                        { this.props.link ? <Button tag={Link} to={{
                                pathname: `class/${user.ID}`,
                                user:user
                            }}>
                            {user.CurrentProject && user.CurrentProject.ID > 0 ? user.CurrentProject.Title : 'Text.me' }
                        </Button> : null }
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default StudentCard;