import React, { Component } from 'react';

import {
    Progress,
    Table
  } from "reactstrap";

class ChartStudentActivities extends Component {
    render() {
        return (
            <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">Projets</th>
                        <th scope="col">Inscrits</th>
                        <th scope="col">Réussites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th scope="row">Activité 1</th>
                        <td>42</td>
                        <td>
                            <div className="d-flex align-items-center">
                            <span className="mr-2">90%</span>
                            <div>
                                {/* <Progress
                                max="100"
                                value="90"
                                barClassName="bg-gradient-danger"
                                /> */}
                                <Progress max="100" multi>
                                    <Progress className='progress-b' bar value="40"  />
                                    <Progress className='progress-g' bar value="50"  />
                                </Progress>
                            </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">Activité 2</th>
                        <td>40</td>
                        <td>
                            <div className="d-flex align-items-center">
                            <span className="mr-2">70%</span>
                            <div>
                                <Progress max="100" multi>
                                    <Progress className='progress-b' bar value="20"  />
                                    <Progress className='progress-g' bar value="50"  />
                                </Progress>
                            </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">Activité 3</th>
                        <td>38</td>
                        <td>
                            <div className="d-flex align-items-center">
                            <span className="mr-2">80%</span>
                            <div>
                                <Progress max="100" multi>
                                    <Progress className='progress-b' bar value="50"  />
                                    <Progress className='progress-g' bar value="30"  />
                                </Progress>
                            </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">Activité 4</th>
                        <td>39</td>
                        <td>
                            <div className="d-flex align-items-center">
                            <span className="mr-2">75%</span>
                            <div>
                            <Progress max="100" multi>
                                    <Progress className='progress-b' bar value="35"  />
                                    <Progress className='progress-g' bar value="40"  />
                                </Progress>
                            </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">Activité 5</th>
                        <td>24</td>
                        <td>
                            <div className="d-flex align-items-center">
                            <span className="mr-2">30%</span>
                            <div>
                                <Progress max="100" multi>
                                    <Progress className='progress-b' bar value="15"  />
                                    <Progress className='progress-g' bar value="15"  />
                                </Progress>
                            </div>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </Table>
        );
    }
}

export default ChartStudentActivities;