import React, { Component, Fragment } from 'react';

class Logs extends Component {
    render() {
        const log = this.props.log
        const color = () => {
            switch (log.type) {
                case 'Correction':
                    return 'table-primary'
                case 'Corrigé':
                    return 'table-success'
                // case 'Validation':
                //     return 'table-success'
                case 'Aide':
                    return 'table-warning'
                case 'Achat':
                    return 'table-info'
            }
        }
        return (
            <Fragment>
                <tbody>
                    <tr className={color()}>
                    <th scope="row">{log.hour}</th>
                    <td>{log.action}</td>
                    <td>{log.feedback}</td>
                    </tr>
                </tbody>
            </Fragment>
        );
    }
}

export default Logs;