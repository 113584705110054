import React, { Component, Fragment } from 'react';
import '../../assets/css/projects.scss'
import { Button } from 'reactstrap';

class Project extends Component {
    state = {
        subscribe: {}
    }
    componentDidMount() {
       this.subscribe()
    }
    subscribe() {
        let subscribe = {}
        if (this.props.project.available === 0) {
            subscribe = {
                color: 'danger',
                text: 'Verrouillé'
            }
        }
        else if (this.props.project.available === 1) {
            subscribe = {
                color: 'success',
                text: 'Déverrouillé'
            }
        }
        else if (this.props.project.available === 2) {
            subscribe = {
                color: 'primary',
                text: 'En cours'
            }
        }
        else {
            subscribe = {
                color: 'default',
                text: 'Terminé'
            }
        }
        this.setState({
            subscribe: subscribe
        })
    }
    render() {
        const project = this.props.project
        return (
            <Fragment>
                <tbody>
                    <tr>
                    <th scope="row">{Number(this.props.id) + 1}</th>
                    <td className='projectName'>{project.name}</td>
                    <td className='projectDescription'>{project.description}</td>
                    <td>{this.state.subscribe ? <Button style={{width:'100%'}} color={this.state.subscribe.color}>{this.state.subscribe.text}</Button> : null}</td>
                    </tr>
                </tbody>
            </Fragment>
        );
    }
}

export default Project;