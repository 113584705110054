import React, { Component, Fragment } from 'react';
import { Card, CardBody, Row, CardTitle, Col, Button } from 'reactstrap';

class ShopCard extends Component {
    render() {
        return (
            <Fragment>
                <Card className="shop-card mb-4 mb-xl-0 shadow">
                    <CardBody>
                    <Row>
                        <Col>
                            <CardTitle
                                tag="h2"
                                className="text-uppercase mb-0"
                            >
                                Article
                            </CardTitle>
                            Exercitation fugiat velit esse quis sit.
                        </Col>
                        <Col>
                            <div className='text-center'>
                                <img src={require('../../../assets/img/city.jpg')} className='w-100' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='shop-details'>
                        <Col className='shop-info'>
                            <i className="far fa-gem text-info" /> 42
                            <Row>
                            </Row>
                        </Col>
                        <Col className='text-center'>
                                <Button outline color='success'>Acheter</Button>
                        </Col>
                    </Row>
                    </CardBody>
                </Card>
                </Fragment>
        );
    }
}

export default ShopCard;