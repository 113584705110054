import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import UserList from './UserList';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import StudentCard from '../../../components/Card/StudentCard';
import { students } from '../../../helpers/fake';

class Classes extends Component {
    state = {
        users: {},
        classId: 1,
        token: 'w9U3vRsC1xUItz78VomWDE6d53meapSjhHUK2+73g/M='
    }
    componentDidMount() {
        // Axios.get(`http://localhost:5000/teacher/class/${this.state.classId}`, {
        //     headers: { Authorization: `Bearer ${this.state.token}` }
        // }).then(res => {
        //     console.log('data: ',res)
        //     this.setState({
        //         users: res.data
        //     })
        // }).catch(err => {
        //     console.log('error: ', err)
        // })
        this.setState({
            users: students
        })
    }
    render() {
        return (
            <Container fluid>
                <h1 className='text-white'>Ma classe</h1>
                {/* <Card className='shadow'>
                    <CardBody>
                        <h1>Ma classe</h1>
                        </CardBody>
                    </Card> */}
                    {this.state.users ? <UserList users={this.state.users} lg={2} /> : null}
                
            </Container>
        );
    }
}

export default Classes;