import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import ProjectList from './ProjectList';
import Holygraph from './Holygraph';

class Projects extends Component {
    render() {
        return (
            <Fragment>
                <Holygraph user={this.props.location && this.props.location.user ? this.props.location.user : null} role={this.props.location.pathname === '/teacher/project' ? 1 : 0} />
                {/* <Container fluid>
                <Card>
                    <CardBody>
                <ProjectList />

                    </CardBody>
                </Card>
                </Container>*/}
            </Fragment> 
        );
    }
}

export default Projects;