import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, CardTitle, CardHeader, Col, CardFooter, Button, ListGroup, ListGroupItem } from 'reactstrap';

import { Graph } from "react-d3-graph";
import { Link } from 'react-router-dom';
 

class Holygraph extends Component {
    state = {
        node: [
            {
                id: "Bonjour",
                description: `Tu as déjà fait cette activité avant même qu'on se rencontre ! Tu te souviens ? Quand tu as dû débrancher la souris et le clavier. C'était la toute première.`,
                DescriptionTeacher: `Cette activité a pour but de s’assurer que tous les élèves commencent avec les mêmes bases et ont les compétences nécessaires pour progresser dans le cursus.
                Ils devront manipuler le matériel informatique. Nous proposons dans cette activité, des manipulations simples et sans danger pour l’élève et le matériel.`,
                subtitle: 'Première activité obligatoire',
                type: 'Collectif',
                time: 'Une séance',
                instructions: 'Classe divisée en deux groupes',
                x: 100,
                y: 100,
                svg: require('../../assets/img/svg/Bonjour.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_1.1.pdf')
            },
            {
                id: "Sesame ouvres toi !",
                description: `Maintenant que tu as toutes les clés en main tu pourras accéder à ton tableau de bord ! C'est avec tous les outils que tu vas découvrir que tu vas pouvoir créer et progresser dans Iota ! 

                Toute la classe fait partie de l'aventure ! 
                
                Si tu as un peu de mal ! Demande aux autres de t'aider ;-) Sinon demande au chatbot.`,
                DescriptionTeacher: `Dans cette activité l’élève découvrira l’interface qui l’accompagnera tout au long de sons cursus. Il pourra se familiariser avec cet outil à travers différentes actions à effectuer dans l’intégralité de l’intra.
                
                Étant la deuxième activité, son niveau de difficulté est faible. Il est quand même nécessaire d’encourager et de mettre en avant l’apprentissage par pair en incitant les élèves à s’entraider.
                `,
                subtitle: 'Deuxième activité obligatoire pour comprendre l’éco-système du cursus',
                type: 'Collectif',
                time: 'Une séance',
                instructions: null,
                x: 300,
                y: 100,
                svg: require('../../assets/img/svg/Sesame_ouvres_toi.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_1.2.pdf')
            },
            {
                id: "AT 1.3",
                description: `Dolore nulla duis minim esse eu enim dolor esse duis. Velit reprehenderit occaecat laboris duis reprehenderit dolor.`,
                DescriptionTeacher: `Amet cillum cillum commodo esse nisi duis cillum est aliqua commodo reprehenderit ullamco. Aliquip aliquip velit eiusmod consectetur tempor cupidatat cupidatat sit laboris consequat commodo dolor ipsum reprehenderit. Id qui cillum cupidatat exercitation in adipisicing aliquip exercitation do. Et aliqua ullamco quis veniam aliqua irure dolore exercitation aliqua.`,
                subtitle: null,
                type: 'Collectif',
                time: 'Une séance',
                instructions: null,
                x: 500,
                y: 100,
                svg: require('../../assets/img/svg/share.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_1.1.pdf')
            },
            {
                id: "Text.me",
                description: `Vous êtes un reporter hors pair ! Dans cette séance vous allez devoir interviewer des personnes mondialement connus, ou pas encore. Il s'agit de vos camarades de classes. Vous devrez alors lui poser des questions sur ce qu'il aime, ce qu'il n'aime pas, ses préférences, etc ...               Vous devrez ensuite écrire cette interview comme un vrai journal, en mettant de la couleur, en soulignant, vous devez être créatif ! 
                Tu devras ensuite envoyer ton interview sur l'intra pour que les autres puissent le lire et donne leur avis. 
                
                n'hésite pas à partager tes conseils et tes astuces de présentation à tes camarades ! Et comme toujours, le chatbot sera là pour t'aider si tu en as besoin.`,
                DescriptionTeacher: `Dans cette activité les élèves vont pouvoir aborder la prise en main d’un éditeur de texte. Ils vont pouvoir découvrir les différentes options d’éditions qu’offre un traitement de texte et ainsi travailler la mise en page.
                Pour pouvoir accompagner au mieux l’enfant dans cette activité, l’éditeur de texte est inclus dans l’intra. L’élève pourra découvrir les différents types de mise en forme ainsi que l’utilisation de caractères spéciaux.`,
                subtitle: null,
                type: 'Collectif',
                time: 'Une séance',
                instructions: null,
                x: 700,
                y: 200,
                svg: require('../../assets/img/svg/Text.me.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_2.1.pdf')
            },
            {
                id: "Passera ou passera pas",
                description: `Gere la circulation`,
                DescriptionTeacher: `Anim do excepteur ad sunt pariatur cupidatat ad sit velit cillum enim. Tempor est do cillum anim ipsum exercitation eiusmod cupidatat mollit. Officia voluptate qui occaecat reprehenderit sunt commodo sit sunt. Enim nulla ex mollit aliquip eiusmod amet incididunt in deserunt et enim. In dolore laborum tempor minim magna exercitation tempor nulla veniam eu esse. Reprehenderit cillum nisi occaecat exercitation commodo veniam non in.`,
                subtitle: null,
                type: 'Collectif',
                time: 'Une séance',
                instructions: null,
                x: 700,
                y: 0,
                svg: require('../../assets/img/svg/Passera_passera_pas.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_3.1.pdf')
            },
            {
                id: "Mon univers",
                description: `Apprends à chercher une image sur internet, à dessiner sur paint ! Crées l’univers de ton fond d’écran que tu pourras faire évoluer tout le long de ton cursus ! 
                C'est toi l'artiste et ton écran est ta toile ! 
                
                N'hésites à partager tes idées et tes créations avec les autres, tu pourras peut-être les insiprer, et eux aussi !`,
                DescriptionTeacher: `Cette activité permettra aux élèves de créer leur fond d’écran avec une image ou un dessin en fonction d’un thème. Pour ce faire, ils apprendront à aller chercher une image libre de droit sur internet, la sauvegarder et la manipuler pour l’utiliser en fond d’écran. Ensuite, ils apprendront à faire un dessin sur paint, qu’ils pourront également utiliser pour leur fond d’écran. `,
                subtitle: null,
                type: 'Individuel',
                time: 'Une séance',
                instructions: 'Inscription recommandé : 2 minimum',
                x: 700,
                y: 400,
                svg: require('../../assets/img/svg/mon_univers.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_2.2.pdf')
            },
            {
                id: "S.O.S !",
                description: `Maintenant que tu as découvert la circulation, des voitures et des métros. Que tu as pu trouver ton âge avec ta pointure, maintenant tu vas devoir enrichir ta ville ! 
                Pour que la ville garde une mémoire de toi, tu dois envoyer le texte que tu as écrit dans une autre activité dans la bibliothèque de la ville. Sauf que voilà ... La bibliothèque ne peut que lire le morse ... et rien d'autre. 
                
                Tu vas donc devoir trouver une machine qui traduit ton texte en morse ... et en morse en texte pour que tu puisses lire les autres textes. 
                
                C'est quoi le morse ? Pour le savoir, clique-ici. 
                
                Si tu as besoin d'aide tu sais que tu auras toujours quelqu'un qui pourra t'aider. Mais le mieux serait d'essayer plusieurs fois ! Sinon le chatbot est toujours avec toi. `,
                DescriptionTeacher: `Les élèves devront créer un algorithme qui permet de traduire du texte en morse, et inversement. Pour faciliter celle-ci, un imaginaire autour de la ville sera créé. Il devront alimenter la bibliothèque de ville, de textes qu’ils auront rédigés dans des précédentes activités (2.1). Cette bibliothèque ne peut que recevoir des textes en morses. C’est la raison pour laquelle ils devront créer un traducteur.`,
                subtitle: null,
                type: 'Individuel',
                time: 'Une séance',
                instructions: 'Inscription recommandé : 2 minimum',
                x: 700,
                y: -200,
                svg: require('../../assets/img/svg/S.O.S.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_3.4.pdf')
            },
            {
                id: "Projet commun",
                description: `Maintenant que tu as découvert la circulation, des voitures et des métros. Que tu as pu trouver ton âge avec ta pointure, maintenant tu vas devoir enrichir ta ville ! 
                Pour que la ville garde une mémoire de toi, tu dois envoyer le texte que tu as écrit dans une autre activité dans la bibliothèque de la ville. Sauf que voilà ... La bibliothèque ne peut que lire le morse ... et rien d'autre. 
                
                Tu vas donc devoir trouver une machine qui traduit ton texte en morse ... et en morse en texte pour que tu puisses lire les autres textes. 
                
                C'est quoi le morse ? Pour le savoir, clique-ici. 
                
                Si tu as besoin d'aide tu sais que tu auras toujours quelqu'un qui pourra t'aider. Mais le mieux serait d'essayer plusieurs fois ! Sinon le chatbot est toujours avec toi. `,
                DescriptionTeacher: `Dolore eiusmod ea quis reprehenderit dolore sint anim cupidatat incididunt non esse. Adipisicing pariatur qui cupidatat anim dolor pariatur ullamco cupidatat eu ullamco nulla aliquip. Est nisi in excepteur cupidatat et dolore proident. Fugiat aliquip et tempor enim Lorem labore.`,
                subtitle: null,
                type: 'Collectif',
                time: 'Une séance',
                instructions: null,
                x: 300,
                y: -200,
                size: 800,
                svg: require('../../assets/img/svg/star.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_1.1.pdf')
            },
            {
                id: "Projet bonus",
                description: `Maintenant que tu as découvert la circulation, des voitures et des métros. Que tu as pu trouver ton âge avec ta pointure, maintenant tu vas devoir enrichir ta ville ! 
                Pour que la ville garde une mémoire de toi, tu dois envoyer le texte que tu as écrit dans une autre activité dans la bibliothèque de la ville. Sauf que voilà ... La bibliothèque ne peut que lire le morse ... et rien d'autre. 
                
                Tu vas donc devoir trouver une machine qui traduit ton texte en morse ... et en morse en texte pour que tu puisses lire les autres textes. 
                
                C'est quoi le morse ? Pour le savoir, clique-ici. 
                
                Si tu as besoin d'aide tu sais que tu auras toujours quelqu'un qui pourra t'aider. Mais le mieux serait d'essayer plusieurs fois ! Sinon le chatbot est toujours avec toi. `,
                DescriptionTeacher: `Tempor eu id minim ut laboris aute esse tempor fugiat. Mollit cillum magna quis laborum culpa elit velit minim pariatur sunt. Tempor non nulla et enim ad pariatur eiusmod ipsum aliquip nostrud eu veniam sint.`,
                subtitle: null,
                type: 'Individuel',
                time: 'Une séance',
                instructions: null,
                x: 800,
                y: 100,
                size: 400,
                svg: require('../../assets/img/svg/star.svg'),
                pdf: require('../../assets/pdf/projects/F.ACTIVITE_1.1.pdf')
            }
        ],
        links: [
            { source: "Bonjour", target: "Sesame ouvres toi !" },
            { source: "Sesame ouvres toi !", target: "AT 1.3" },
            { source: "AT 1.3", target: "Text.me" },
            { source: "Text.me", target: "Mon univers" },
            { source: "AT 1.3", target: "Passera ou passera pas" },
            { source: "Text.me", target: "Passera ou passera pas" },
            { source: "Passera ou passera pas", target: "S.O.S !" }
        ],
        selectedProject: null
    }
    decorateGraphNodesWithInitialPositioning = nodes => {
        return nodes.map(n =>
            Object.assign({}, n, {
                x: n.x + 100,
                y: n.y + 300,
            })
        )
    };

    render() {
        // graph payload (with minimalist structure)
        const data = {
            nodes: this.decorateGraphNodesWithInitialPositioning(this.state.node),
            links: this.state.links
        };
        
        // the graph configuration, you only need to pass down properties
        // that you want to override, otherwise default ones will be used
        const myConfig = {
            nodeHighlightBehavior: true,
            height: '50rem',
            width: '100%',
            staticGraph: true,
            highlightDegree: 0,
            minZoom: 0.5,
            maxZoom: 1.5,
            node: {
                color: "white",
                size: 640,
                fontSize: 0,
                highlightFontSize: 16,
                highlightFontWeight: 600,
                fontColor: '#AD8247',
                // renderLabel: false,
            }
        };
        
        // graph event callbacks
        const onClickGraph = () => {
            this.setState({
                selectedProject: null
            })
        };
        
        // const onClickNode = (nodeId) => {
        //     // this.setState({
        //     //     selectedProject: this.state.node[this.state.node.map(function(e) { return e.id; }).indexOf(nodeId)].description
        //     // })
        // };
        
        // const onDoubleClickNode = (nodeId) => {
        //     window.alert(`Double clicked node ${nodeId}`);
        // };
        
        // const onRightClickNode = (event, nodeId) => {
        //     window.alert(`Right clicked node ${nodeId}`);
        // };
        
        const onMouseOverNode = (nodeId) => {
            const whichNode = this.state.node.map(function(e) { return e.id; }).indexOf(nodeId)
            this.setState({
                selectedProject: {
                    description: this.state.node[whichNode].description,
                    DescriptionTeacher: this.state.node[whichNode].DescriptionTeacher,
                    name: nodeId,
                    pdf: this.state.node[whichNode].pdf,
                    subtitle: this.state.node[whichNode].subtitle,
                    type: this.state.node[whichNode].type,
                    time: this.state.node[whichNode].time,
                    instructions: this.state.node[whichNode].instructions,
                }
            })
        };
        
        // const onMouseOutNode = (nodeId) => {
        //     window.alert(`Mouse out node ${nodeId}`);
        // };
        
        // const onClickLink = (source, target) => {
        //     window.alert(`Clicked link between ${source} and ${target}`);
        // };
        
        // const onRightClickLink = (event, source, target) => {
        //     window.alert(`Right clicked link between ${source} and ${target}`);
        // };
        
        // const onMouseOverLink = (source, target) => {
        //     window.alert(`Mouse over in link between ${source} and ${target}`);
        // };
        
        // const onMouseOutLink = (source, target) => {
        //     window.alert(`Mouse out link between ${source} and ${target}`);
        // };
        
        // const onNodePositionChange = (nodeId, x, y) => {
        //     window.alert(`Node ${nodeId} is moved to new position. New position is x= ${x} y= ${y}`);
        // };
        
        const TeacherDescription = () => {
            const selected = this.state.selectedProject
            return (
                <Fragment>
                        <div className='project-subtitle'>
                            { selected.subtitle ? <Fragment><em>{selected.subtitle}</em></Fragment> : null }
                            { selected.type ? <Fragment><strong>{selected.type}</strong></Fragment> : null }
                            { selected.time ? <Fragment><strong>Durée : {selected.time}</strong></Fragment> : null }
                            { selected.instructions ? <Fragment><strong>{selected.instructions}</strong></Fragment> : null }
                        </div>
                        {selected.DescriptionTeacher}
                </Fragment>
            )
        }

        return (
            <Container className='holygraph' fluid>
                <h1>Holygraph{this.props.user ? <Fragment> de <u>{this.props.user.FirstName} {this.props.user.LastName}</u></Fragment> : null}</h1>
                <div className='text-center'>
                    <div className="stars small"></div>
                    <div className="stars medium"></div>
                    <div className="stars large"></div>
                    <Graph
                        id="holygraph" // id is mandatory, if no id is defined rd3g will throw an error
                        data={data}
                        config={myConfig}
                        // onClickNode={onClickNode}
                        // onDoubleClickNode={onDoubleClickNode}
                        // onRightClickNode={onRightClickNode}
                        onClickGraph={onClickGraph}
                        // onClickLink={onClickLink}
                        // onRightClickLink={onRightClickLink}
                        onMouseOverNode={onMouseOverNode}
                        // onMouseOutNode={onMouseOutNode}
                        // onMouseOverLink={onMouseOverLink}
                        // onMouseOutLink={onMouseOutLink}
                        // onNodePositionChange={onNodePositionChange}
                    />
                </div>

                { this.state.selectedProject ? <Card className='projectDescription'>
                    <CardHeader>
                        <h3>
                            {this.state.selectedProject.name}
                        </h3>
                    </CardHeader>
                    <CardBody>
                        { this.props.role ? TeacherDescription() : this.state.selectedProject.description }
                    </CardBody>
                    { this.props.role ? <CardFooter>
                        <Button tag={Link} color="default" size="lg" block outline>Voir la page projet</Button>
                        <Button tag={Link} target="_blank" to={this.state.selectedProject.pdf} color="default" size="lg" block>Télécharger le PDF</Button>
                    </CardFooter> : null }
                </Card> : null }
            </Container>
        );
    }
}

export default Holygraph;