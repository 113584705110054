import React, { Component } from 'react';
import { Card, Container, CardBody, CardHeader, Collapse } from 'reactstrap';

class Manage extends Component {
    state = {
        collapse: 0,
        cards: [
            {
                id: 1,
                color: 'primary',
                title: 'Le parcours d’indépendance de l’élève ?',
                description: `Le but du parcours proposé par Iota est principalement basé sur l’indépendance de l’élève face à son apprentissage. L’objectif est qu’ils s’entraident entre eux et qu’ils trouvent eux- même leur propre méthode pour évoluer. Cependant toute liberté est méritée. C’est pour cela que nous avons mis en place un système de passeport. Au début du cursus il devra respecter de nombreuses contraintes qui construiront un environnement propice au développement de l’élève. Au fur et à mesure des activités, les élèves atteindront un nouveau palier qui leur permettra d’avoir un peu plus de liberté. Vous pouvez selon le profil de votre classe, configurez et adapter ce parcours d’indépendance. 
                Pour résumé : L’indépendance se construit principalement sur l’ordre.`
            },
            {
                id: 2,
                color: 'info',
                title: 'Est-ce que mon intervention est essentielle pour aider un élève en difficulté ?',
                description: `Même si l’on tend à faire gagner l’autonomie de l’élève en encourageant l’entraide entre pairs, votre présence est essentielle. Vous représentez la bienveillance et une sécurité fondamentale dans l’apprentissage de l’élève. 
                Avant de recevoir un avertissement sur votre tableau de bord, l’élève aura déjà sollicité les aides et les indications du chatbot ainsi que le coup de main de ses camarades. Si malgré toutes ces interventions (divines ?) l’élève se retrouve encore dans une situation bloquante vous serez donc son dernier référent, celui qui pourra l’aider à avancer et à avoir confiance.`
            },
            {
                id: 3,
                color: 'success',
                title: 'Comment aider un élève en difficulté ?',
                description: `Nous vous conseillons d'accompagner l’élève dans sa démarche. Vous devez plus  l’aider à trouver le bon raisonnement que de le lui donner directement la bonne réponse. 
                Pour ce faire nous vous fournirons du contenu supplémentaire.`
            },
            {
                id: 4,
                color: 'warning',
                title: 'Vous constatez une trop grande disparité entre les filles et les garçons ?',
                description: `Si vous vous apercevez que la répartition des rôles n’est pas équitable, vous pouvez par exemple, au début de la prochaine séance sensibiliser et valoriser les différents rôles de l’activité auprès de la classe pour qu’elle attire une plus grande mixité. 

                Vous pourrez par exemple mettre en avant le travail d’une élève qui a produit du contenu de référence, l’intra vous préviendra et vous pourrez le mettre en avant auprès de la classe.`
            },
            {
                id: 5,
                color: 'danger',
                title: 'Un cursus modulable selon vos besoins.',
                description: `Iota n’est pas un simple cours sur “le numérique” mais englobe de nombreuses notions qui permettront de valider des compétences issues des matières académiques tel que, les mathématiques et même l’histoire. Vous pouvez donc mettre plus en avant une activité selon vos besoins et les demandes pédagogiques de votre programme scolaire.`
            },
    ]
    }
    render() {
        const {cards, collapse} = this.state;
        const toggle = (e) => {
            let event = e.target.dataset.event;
            this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
          }
        const showCards = () => {
            return cards.map(index => {
                return (
                    <Card outline color={index.color} className='mb-3' key={index.id}>
                        <CardHeader className={`h3 bg-${index.color}`}  onClick={toggle} data-event={index.id}>{index.title}</CardHeader>
                        <Collapse isOpen={collapse === index.id}>
                            <CardBody>
                                {index.description}
                            </CardBody>
                        </Collapse>
                    </Card>
                )
            })
        }
        return (
            <Container fluid>
                <Card>
                    <CardHeader><h1>Gestion de classe</h1></CardHeader>
                    <CardBody>
                        {showCards()}
                    </CardBody>
                </Card>
                
            </Container>
        );
    }
}

export default Manage;