import React from "react";

// reactstrap components
import { Row } from "reactstrap";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-end">
            <div className="copyright justify-content-center justify-content-xl-end text-muted">
              © 2020{" "}
              <Link
                className="font-weight-bold ml-1"
                to="/teacher"
              >
                Iota
              </Link>
            </div>
        </Row>
      </footer>
    );
  }
}

export default Footer;