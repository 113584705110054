import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Container,
    CardTitle,
    Progress
  } from "reactstrap";

import ChartProjectsLevel from '../../../components/Charts/ChartProjectsLevel';
import ChartStudentActivities from '../../../components/Charts/ChartStudentActivities';
  


class Dashboard extends Component {
    render() {
        return (
            <Container fluid>
                <Row className='mb-5'>
                <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 shadow">
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h2"
                            className="text-muted mb-0"
                          >
                            Élèves en difficultés
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            4
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                            <i className="fas fa-chevron-down" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 24%
                        </span>{" "}
                        <span className="text-nowrap">Depuis la dernière séance</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 shadow">
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h2"
                            className="text-muted mb-0"
                          >
                            Élèves dans les temps
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            16
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-check" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Depuis la dernière séance</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 shadow">
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h2"
                            className="text-muted mb-0"
                          >
                            Élèves en avance
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            11
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                            <i className="fas fa-chevron-up" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 30%
                        </span>{" "}
                        <span className="text-nowrap">Depuis la dernière séance</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                <h2 className="mb-0">Vision globale par activité et niveau</h2>
                                </Col>
                            </Row>
                            </CardHeader>
                            <CardBody>
                              {/* <ChartLevel /> */}
                              <ChartProjectsLevel />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                    <Card className="shadow chart-student-activities">
                    <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col>
                        <h2 className="mb-0">Élèves par activité</h2>
                        </Col>
                        <Col className="chart-student-legend">
                          <div className="sexe-legend">  
                            <i className="fas fa-mars student-mars"></i>
                            <Progress max="100" multi>
                              <Progress className='progress-b' bar value="50"  />
                              <Progress className='progress-g' bar value="50"  />
                            </Progress>
                            <i className="fas fa-venus student-venus"></i>
                          </div>
                        <Button
                            color="primary"
                            onClick={e => e.preventDefault()}
                            size="sm"
                        >
                            Voir tout
                        </Button>
                        </Col>
                    </Row>
                    </CardHeader>
                    <ChartStudentActivities />
                </Card>
                </Col>
                </Row>
            </Container>
        );
    }
}

export default Dashboard;