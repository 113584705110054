import Login from "./views/Login/Login.js";
import Dashboard from "./views/Teacher/Dashboard/Dashboard.js";
import Project from "./views/Projects/Projects.js";
import Classes from "./views/Teacher/Classes/Classes.js";
import Forum from "./views/Teacher/Forum/Forum.js";
import Profile from "./views/Profile/Profile.js";
import Holygraph from "./views/Projects/Holygraph.js";
import Shop from "./views/Student/Shop/ShopCategories.js";
import ShopList from "./views/Student/Shop/ShopList.js";
import CurrentProject from "./views/Student/Project/CurrentProject.js";
import Manage from "./views/Teacher/Classes/Manage.js";

var routes = [
    {
      path: "/dashboard",
      name: "Vision globale",
      icon: "ni ni-chart-bar-32 text-orange",
      component: Dashboard,
      layout: "/teacher",
      visible: true
    },
    {
      path: "/project/holygraph",
      name: "Holygraph",
      icon: "ni ni-books text-danger",
      component: Holygraph,
      layout: "/teacher",
      visible: false
    },
    {
      path: "/project",
      name: "Activités",
      icon: "ni ni-books text-info",
      component: Project,
      layout: "/teacher",
      visible: true
    },
    {
      path: "/class/manage",
      name: "Points pedagogiques",
      icon: "ni ni-circle-08 text-danger",
      component: Manage,
      layout: "/teacher",
      visible: false
    },
    {
      path: "/class/:student",
      name: "Profil",
      icon: "ni ni-circle-08 text-danger",
      component: Profile,
      layout: "/teacher",
      visible: false
    },
    {
      path: "/class",
      name: "Élèves",
      icon: "ni ni-hat-3 text-success",
      component: Classes,
      layout: "/teacher",
      visible: true,
    },
    {
      path: "/forum",
      name: "Chat",
      icon: "ni ni-send text-primary",
      component: Forum,
      layout: "/teacher",
      visible: true
    },
    {
      path: "/project/current",
      name: "Projets",
      icon: "ni ni-controller text-orange",
      component: CurrentProject,
      layout: "/student",
      visible: false
    },
    {
      path: "/project",
      name: "Projets",
      icon: "ni ni-controller text-orange",
      component: Project,
      layout: "/student",
      visible: true
    },
    {
      path: "/shop/:category",
      name: "Shop",
      icon: "ni ni-circle-08 text-danger",
      component: ShopList,
      layout: "/student",
      visible: false
    },
    {
      path: "/shop",
      name: "Shop",
      icon: "ni ni-shop text-success",
      component: Shop,
      layout: "/student",
      visible: true
    },
  ];
  export default routes;