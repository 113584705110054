import React, { Component } from 'react';
import { Container, Row, Card, CardHeader, CardBody, Col, Progress, CardTitle, Button, Badge, Collapse } from 'reactstrap';
import '../../assets/css/profile.css'
import LogsList from './LogsList';
import StudentCard from '../../components/Card/StudentCard';
import LevelCard from '../../components/Card/LevelCard';
import { getRandomInt } from '../../helpers/functions'
import { API, tmpTOKEN } from '../../helpers/env'
import Axios from 'axios';

import Moment from 'react-moment'
import { Link } from 'react-router-dom';
require('moment/locale/fr.js');
Moment.globalLocale = 'fr';

class Profil extends Component {
    state = {
        classId: 1,
        collapse: 1
    }
    componentDidMount() {
        Axios.get(`${API}teacher/student/${this.props.match.params.student}/class/${this.state.classId}`, {
            headers: { Authorization: `Bearer ${tmpTOKEN}` }
        }).then(res => {
            this.setState({
                users: res.data
            })
        }).catch(err => {
            console.log('error: ', err)
        })
    }
    render() {
        const date = new Date()
        const toggle = () => {
            this.setState({
                collapse: !this.state.collapse
            })
        }
        return (
            <Container fluid>
                <Row>
                    <Col lg={3}>
                    <StudentCard user={this.props.location && this.props.location.user ? this.props.location.user : null} />
                    </Col>
                    <Col>
                    <Row>
                    <Card className='shadow w-100 mb-3'>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col>
                                <h2>
                                    <Moment format="ll">{date}</Moment>
                                </h2>
                                </Col>
                            </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h3>Projet en cours</h3>
                                        <Badge color="primary">{this.props.location && this.props.location.user ? this.props.location.user.CurrentProject.Title : 'Text.me'}</Badge>
                                    </Col>
                                    <Col>
                                        <h3>État émotionnel</h3>
                                        <div className='mood'>
                                            <i className="far fa-smile"></i> – <i className="far fa-grin"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <h3>Projet terminés</h3>
                                        <div className='past-projects'>
                                            <Badge color="success">AT 1.3</Badge>
                                            <Badge color="success">Sésame ouvres toi !</Badge>
                                            <Badge color="success">Bonjour</Badge>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        <LevelCard level={getRandomInt(1, 5)} xp={getRandomInt(0, 100)} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        </Row>
                        <Row>
                            <Button tag={Link} to={{
                                pathname:"/teacher/project",
                                user: this.props.location && this.props.location.user ? this.props.location.user : null}} color="primary" size="lg" className='mb-3 mr-0 holy-btn' block>Holygraph</Button>
                            {/* Peer Correcting */}
                            <Card className='shadow w-100 mb-3'>
                                <CardHeader onClick={toggle} className='h2 pointer'>
                                    Peer-correcting
                                    <span className='float-right' aria-hidden>
                                        {this.state.collapse ? '–' : '+'}
                                    </span>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapse}>
                                    <CardBody>
                                        <LogsList />
                                    </CardBody>
                                </Collapse>
                            </Card>
                            <Card className='shadow w-100 help-required'>
                                <CardHeader className='h2 pointer'>
                                    Aides demandées
                                </CardHeader>
                                    <CardBody className='d-flex'>
                                        <Button color='primary' outline>Chatbot <Badge color="primary">7</Badge></Button>
                                        <Button color='danger' outline>Professeur <Badge color="danger">3</Badge></Button>
                                        <Button color='success' outline>Élève <Badge color="success">4</Badge></Button>
                                    </CardBody>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Profil;