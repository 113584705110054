export const students = [
    { "ID": 1, "status": 0, "points": 1416, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Vincent", "LastName": "Bussière", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 2, "status": 2, "points": 4377, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Inès", "LastName": "Pinneau", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 3, "status": 1, "points": 3572, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Georges", "LastName": "Bureau", "CurrentProject": { "ID": 0 }, "Progresses": null },
    { "ID": 4, "status": 1, "points": 3146, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Julie", "LastName": "Mousseau", "CurrentProject": { "ID": 2, "Title": "Mon univers" }, "Progresses": null },
    
    { "ID": 5, "status": 1, "points": 3115, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Leone", "LastName": "Vaillancour", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 6, "status": 0, "points": 123, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Pauline", "LastName": "Brochu", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 7, "status": 1, "points": 2562, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Richard", "LastName": "Vergne", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 8, "status": 2, "points": 4852, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Eloise", "LastName": "Paiement", "CurrentProject": { "ID": 0 }, "Progresses": null },
    
    { "ID": 9, "status": 1, "points": 3357, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Élodie", "LastName": "Pépin", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 10, "status": 0, "points": 1229, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Lirienne", "LastName": "Gamache", "CurrentProject": { "ID": 2, "Title": "Mon univers" }, "Progresses": null },
    { "ID": 11, "status": 0, "points": 512, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Heloise", "LastName": "Sorel", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 12, "status": 1, "points": 2956, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Phillipa", "LastName": "Busson", "CurrentProject": { "ID": 0 }, "Progresses": null },
    
    { "ID": 13, "status": 0, "points": 487, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Alacoque", "LastName": "Marier", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 14, "status": 2, "points": 4072, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Roslyn", "LastName": "Hughes", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 15, "status": 1, "points": 2748, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Melusina", "LastName": "Bédard", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 16, "status": 2, "points": 4380, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Corinne", "LastName": "Guernon", "CurrentProject": { "ID": 2, "Title": "Mon univers" }, "Progresses": null },
    
    { "ID": 17, "status": 0, "points": 1268, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Olivier", "LastName": "Jalbert", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null },
    { "ID": 18, "status": 2, "points": 4977, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Fabienne", "LastName": "Meunier", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 19, "status": 2, "points": 4454, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Philippe", "LastName": "Lagrange", "CurrentProject": { "ID": 0 }, "Progresses": null },
    { "ID": 20, "status": 1, "points": 3413, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Fabrice", "LastName": "Hébert", "CurrentProject": { "ID": 2, "Title": "Mon univers" }, "Progresses": null },
    
    { "ID": 21, "status": 1, "points": 2619, "Avatar": {}, "UUID": "8699f611-fab0-4f52-a614-100594a6542a", "FirstName": "Camille", "LastName": "Trudeau", "CurrentProject": { "ID": 0 }, "Progresses": null },
    { "ID": 22, "status": 2, "points": 4520, "Avatar": {}, "UUID": "b60d5451-4f6d-42b1-87af-d2edca205c8d", "FirstName": "Carole", "LastName": "Devost", "CurrentProject": { "ID": 2, "Title": "Mon univers" }, "Progresses": null },
    { "ID": 23, "status": 2, "points": 4728, "Avatar": {}, "UUID": "6cd6e7d9-f6b7-4815-9100-e6d9a867c934", "FirstName": "Marlon", "LastName": "Talon", "CurrentProject": { "ID": 2, "Title": "S.O.S !" }, "Progresses": null },
    { "ID": 24, "status": 2, "points": 4378, "Avatar": {}, "UUID": "597b1bd5-f2e8-42be-ac9b-511eb13ea1fd", "FirstName": "Laureen", "LastName": "Boutot", "CurrentProject": { "ID": 1, "Title": "Bonjour", "AdditionalTitle": "Prise en main des différents périphériques" }, "Progresses": null }

]