import React, { Component, Fragment } from 'react';
import { Col, Card, CardBody, Row, CardTitle, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

class Shop extends Component {
    state = {
        category: 'city'
    }
    render() {
        return (
            <Fragment>
                <Container fluid>
                    <div className='cards'>
                            <Card className="card-stats mb-3 shadow">
                                <CardBody>
                                <Link to={`shop/${this.state.category}`}>
                                    <CardTitle
                                        tag="h2"
                                        className="text-center text-uppercase mb-0"
                                    >
                                        Ma ville
                                    </CardTitle>
                                    
                                        <div className='text-center'>

                                        <img src={require('../../../assets/img/city.jpg')} />
                                        </div>
                                </Link>
                                </CardBody>
                            </Card>
                    
                    <Card className="card-stats mb-3 shadow">
                        <CardBody>
                    <Link to={`shop/${this.state.category}`}>
                            <CardTitle
                                tag="h2"
                                className="text-center text-uppercase mb-0"
                            >
                                Mon avatar
                            </CardTitle>
                                <img src={require('../../../assets/img/avatar.png')} />
                    </Link>
                        </CardBody>
                    </Card>
                    
                    <Card className="card-stats mb-3 shadow">
                        <CardBody>
                            <Link to={`shop/${this.state.category}`}>
                            <CardTitle
                                tag="h2"
                                className="text-center text-uppercase mb-0"
                            >
                                Ma maison
                            </CardTitle>
                                <img src={require('../../../assets/img/home.png')} />
                    </Link>
                        </CardBody>
                    </Card>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default Shop;