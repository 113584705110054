import React from "react";
import { Link } from "react-router-dom";
import '../../assets/css/teacherNavbar.css'
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class StudentNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar style={{position:'relative', backgroundColor:'#fda085'}} className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to={this.props.location.pathname}
            >
              {this.props.brandText}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {/* <img
                        alt="..."
                        src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      /> */}
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        John Doe
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Espace personnel</h6>
                  </DropdownItem>
                  {/* <DropdownItem to="/student/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem> */}
                  <DropdownItem to="/student/user-profile" onClick={e => e.preventDefault()}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Paramètres</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/student/user-profile" onClick={e => e.preventDefault()}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem> */}
                  <DropdownItem to="/student/user-profile" onClick={e => e.preventDefault()}>
                    <i className="ni ni-support-16" />
                    <span>Aide</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="/student/user-profile" onClick={e => this.props.history.push('/')}>
                    <i className="ni ni-user-run" />
                    <span>Déconnexion</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default StudentNavbar;