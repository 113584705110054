import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import StudentCard from '../../../components/Card/StudentCard';

class UserList extends Component {
    render() {
        const eachUser = Object.keys(this.props.users)
            .map(key => <StudentCard key={key} id={key} user={this.props.users[key]} link={true} />)
        return (
            <Fragment>
                <Row>
                    <div className='cards'>
                        { eachUser }
                    </div>
                </Row>
            </Fragment>
        );
    }
}

export default UserList;