import React, { Component, Fragment } from 'react';
import { Table } from 'reactstrap';
import Project from './Project';

class ProjectList extends Component {
    state = {
        projects: [
            {
                name: 'Bonjour',
                description: `Tu as déjà fait cette activité avant même qu'on se rencontre ! Tu te souviens ? Quand tu as dû débrancher la souris et le clavier. C'était la toute première.`,
                available: 3
            },
            {
                name: 'Sesame ouvres toi !',
                description: `Maintenant que tu as toutes les clés en main tu pourras accéder à ton tableau de bord ! C'est avec tous les outils que tu vas découvrir que tu vas pouvoir créer et progresser dans Iota ! 

                Toute la classe fait partie de l'aventure ! 
                
                Si tu as un peu de mal ! Demande aux autres de t'aider ;-) Sinon demande au chatbot.`,
                available: 3
            },
            {
                name: 'AT 1.3',
                description: `Dolore nulla duis minim esse eu enim dolor esse duis. Velit reprehenderit occaecat laboris duis reprehenderit dolor.`,
                available: 3
            },
            {
                name: 'Text.me',
                description: `Vous êtes un reporter hors pair ! Dans cette séance vous allez devoir interviewer des personnes mondialement connus, ou pas encore. Il s'agit de vos camarades de classes. Vous devrez alors lui poser des questions sur ce qu'il aime, ce qu'il n'aime pas, ses préférences, etc ...               Vous devrez ensuite écrire cette interview comme un vrai journal, en mettant de la couleur, en soulignant, vous devez être créatif ! 
                Tu devras ensuite envoyer ton interview sur l'intra pour que les autres puissent le lire et donne leur avis. 
                
                n'hésite pas à partager tes conseils et tes astuces de présentation à tes camarades ! Et comme toujours, le chatbot sera là pour t'aider si tu en as besoin.`,
                available: 2
            },
            {
                name: 'Passera ou passera pas',
                description: `Gere la circulation`,
                available: 1
            },
            {
                name: 'Mon univers',
                description: `Apprends à chercher une image sur internet, à dessiner sur paint ! Crées l’univers de ton fond d’écran que tu pourras faire évoluer tout le long de ton cursus ! 
                C'est toi l'artiste et ton écran est ta toile ! 
                
                N'hésites à partager tes idées et tes créations avec les autres, tu pourras peut-être les insiprer, et eux aussi !`,
                available: 0
            },
            {
                name: 'S.O.S !',
                description: `Maintenant que tu as découvert la circulation, des voitures et des métros. Que tu as pu trouver ton âge avec ta pointure, maintenant tu vas devoir enrichir ta ville ! 
                Pour que la ville garde une mémoire de toi, tu dois envoyer le texte que tu as écrit dans une autre activité dans la bibliothèque de la ville. Sauf que voilà ... La bibliothèque ne peut que lire le morse ... et rien d'autre. 
                
                Tu vas donc devoir trouver une machine qui traduit ton texte en morse ... et en morse en texte pour que tu puisses lire les autres textes. 
                
                C'est quoi le morse ? Pour le savoir, clique-ici. 
                
                Si tu as besoin d'aide tu sais que tu auras toujours quelqu'un qui pourra t'aider. Mais le mieux serait d'essayer plusieurs fois ! Sinon le chatbot est toujours avec toi. `,
                available: 0
            }
        ]
    }
    render() {
        const eachProject = Object.keys(this.state.projects)
            .map(key => <Project key={key} id={key} project={this.state.projects[key]} />)
        return (
            <Fragment>
                <Table hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Projet</th>
                        <th>Description</th>
                        <th>Disponible</th>
                        </tr>
                    </thead>
                    { eachProject }
                </Table>
            </Fragment>
        );
    }
}

export default ProjectList;