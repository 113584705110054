import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardTitle, Progress } from 'reactstrap';

class LevelCard extends Component {
    render() {
        const props = this.props
        return (
            <Card className='bg-gradient-danger border-0 card'>
                <CardBody>
                    <Row>
                        <Col>
                            <CardTitle className='text-uppercase text-muted text-white h5'>
                                Passport niv. {3 || props.level}
                            </CardTitle>
                            <Progress multi>
                                <Progress className='progress-divided' bar color="success" value="25" animated />
                                <Progress className='progress-divided' bar color="success" value="25" animated />
                                <Progress className='progress-divided' bar color="success" value="25" animated />
                                <Progress className='progress-divided' bar color="success" value="15" animated />
                            </Progress>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default LevelCard;