import React from "react";
import { Route } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, Card, CardHeader, CardText, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";

// core components
import IndexFooter from "../components/Footers/IndexFooter.js";

import Login from "../views/Login/Login.js";
import { Tabs } from "@feuer/react-tabs";

class Index extends React.Component {
  state = {
    selectedJob: 'professeur',
    error: null
  }
  componentDidMount() {
    document.body.classList.add("bg-index");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-index");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    const studentLog = {
      login: 'student',
      pwd: 'student'
    }
    const teacherLog = {
      login: 'teacher',
      pwd: 'teacher'
    }
    
    const switchJob = (e) => {
      console.log('test')
      // this.setState({selectedJob: !this.state.selectedJob})
    }
    const handleConnect = (e) => {
      e.preventDefault()
      if (this.state.login === studentLog.login && this.state.pwd === studentLog.pwd) {
        this.props.history.push('/student/project')
      }
      else if (this.state.login === teacherLog.login && this.state.pwd === teacherLog.pwd) {
        this.props.history.push('/teacher/dashboard')
      }
      else {
        this.setState({
          error: 'Identifiant ou mot de passe invalide.'
        })
      }
    }
    const handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
    }
    return (
      <>
        <div className="main-content">
          {/* <StudentNavbar /> */}
          <div className="header bg-gradient-warning py-7 py-lg-8">
            <Container>
              <div className="header-body mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6" className='pb-4'>
                    <Card className="shadow">
                      <CardHeader>
                        <Row>
                          <Col className='text-center'>
                            <h6 className="text-uppercase text-muted ls-1 mb-1">42 présente</h6>
                            <h1 className="mb-0">IOTA</h1>
                            </Col>
                          </Row>
                      </CardHeader>
                      <CardText className="p-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </CardText>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="shadow">
                      <CardHeader>
                      <h6 className="text-uppercase text-muted ls-1 mb-1 text-center">Bienvenue</h6>
                      <h2 className="mb-0 h1 text-center">Connexion</h2>
                      </CardHeader>
                      <CardBody>
                        {/* TEMP PRESENTATION */}


                        <Form onSubmit={handleConnect}>
                          {this.state.error ? <Alert color='danger'>{this.state.error}</Alert> : '' }
                          {/* {this.state.success ? loginSuccess() : '' } */}
                          <Col>
                            <FormGroup>
                              <Label for="identifiant">Identifiant</Label>
                              <Input
                                type="text"
                                id="identifiant"
                                name="login"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="password">Mot de passe</Label>
                              <Input
                                type="password"
                                name="pwd"
                                id="password"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <Button color="success" className="mr-3">Connexion</Button>
                          </Col>
                        </Form>


                    </CardBody>
                  </Card>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
        </div>
        <IndexFooter />
      </>
    );
  }
}

export default Index;
