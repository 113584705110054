import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import TeacherLayout from "./layouts/Teacher.js";
import StudentLayout from "./layouts/Student.js";
import IndexLayout from "./layouts/Index.js";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/sidebar.css";
import "./assets/css/style.css";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={props => <IndexLayout {...props} />} />
      <Route path="/teacher" render={props => <TeacherLayout {...props} />} />
      <Route path="/student" render={props => <StudentLayout {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);