import React, { Component } from 'react';
import { Container, Card, CardBody, CardHeader } from 'reactstrap';

class CurrentProject extends Component {
    render() {
        return (
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h1>Activité en cours</h1>
                    </CardHeader>
                    <CardBody>
                        <div className='text-center'>
                            <h2>
                                Phaser ici
                            </h2>
                            <img src={require('../../../assets/img/projects/AT-1/feux.png')} />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

export default CurrentProject;