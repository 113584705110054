import React, { Component } from 'react';
import ShopCard from './ShopCard';
import { Row, Container } from 'reactstrap';

class ShopList extends Component {
    render() {
        return (
            <Container fluid>
                    <div className='cards'>
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                        <ShopCard />
                    </div>
            </Container>
        );
    }
}

export default ShopList;