import React, { Component, Fragment } from 'react';
import {
    Form,
    Col,
    FormGroup,
    Label,
    Input,
    Button
  } from "reactstrap";
import { Redirect } from 'react-router-dom';

class Login extends Component {
    render() {
        const handleSubmit = (e) => {
            e.preventDefault()
        }
        
        const handleChange = (e) => {
            e.preventDefault()
        }
        return (
            <Fragment>
            <Form onSubmit={handleSubmit}>
                {/* {this.state.error ? loginError() : '' }
                {this.state.success ? loginSuccess() : '' } */}
                <Col>
                <FormGroup>
                    <Label for="identifiant">Identifiant</Label>
                    <Input
                    type="text"
                    id="identifiant"
                    name="name"
                    onChange={handleChange}
                    />
                </FormGroup>
                </Col>
                <Col>
                <FormGroup>
                    <Label for="password">Mot de passe</Label>
                    <Input
                    type="password"
                    name="pwd"
                    id="password"
                    onChange={handleChange}
                    />
                </FormGroup>
                </Col>
                <Col>
                    <Button color="success" className="mr-3">Connexion</Button>
                </Col>
            </Form>
        </Fragment>
        );
    }
}

export default Login;