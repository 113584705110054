import React, { Component } from 'react';
import { HorizontalBar } from "react-chartjs-2";

let chart = {
    options: {
      scales: {
        yAxes: [
          {
            categoryPercentage: 0.6,
            barPercentage: 1.0,
            stacked: true,
          }
        ],
        xAxes: [{
          stacked: true,
        }],
      },
    },
    data: {
      labels: ["Activité 1", "Activité 2", "Activité 3", "Activité 4", "Activité 5"],
      datasets: [{
        label: 'Niveau 1',
        data: [40, 60, 60, 50, 30],
        backgroundColor: "#F8BD7C"
    },
    {
        label: 'Niveau 2',
        data: [40, 30, 10, 30, 60],
        backgroundColor: "#E3E3E3"
    },{
        label: 'Niveau 3',
        data: [20, 10, 30, 20, 10],
        backgroundColor: "#FFD855"
    }]
    }
  }

class ChartProjectsLevel extends Component {
    render() {
        return (
            <HorizontalBar
                data={chart.data}
                options={chart.options}
                />
        );
    }
}

export default ChartProjectsLevel;